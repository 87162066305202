import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const DataTable = () => {
  const navigate = useNavigate();
  const rows = [
    {
      name: "Corporate Stratergies and its impact on share prices",
      courseCode: "SIDC-0001-001",
      courseStartDate: "05 JAN 2004",
      courseEndDate: "05 JAN 2004",
      status: "Active",
    },
  ];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead style={{ backgroundColor: "#1e40af" }}>
          <TableRow
            sx={{
              "& th": {
                color: "white",
              },
            }}>
            <TableCell>Course Name</TableCell>
            <TableCell>Course Code</TableCell>
            <TableCell>Course Start Date</TableCell>
            <TableCell>Course End Date</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell
                component="th"
                scope="row"
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => navigate("/newCourse")}>
                {row.name}
              </TableCell>
              <TableCell>{row.courseCode}</TableCell>
              <TableCell>{row.courseStartDate}</TableCell>
              <TableCell>{row.courseEndDate}</TableCell>
              <TableCell>{row.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
