import {
  Box,
  Step,
  StepButton,
  Stepper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  StepLabel,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const SecretariatCourseLists = () => {
  const navigate = useNavigate();
  const courseLists = [
    {
      courseName: "Course-1",
      trainingProvider: "TP-1",
      status: "Active",
      deliveryMedium: "Online",
      steps: [
        { name: "Secretariat", status: true },
        { name: "Manager", status: true },
        { name: "Head", status: false },
        { name: "Working Committee", status: false },
      ],
    },
    {
      courseName: "Course-2",
      trainingProvider: "TP-2",
      status: "Pending",
      deliveryMedium: "Instructor-Led",
      steps: [
        { name: "Secretariat", status: true },
        { name: "Manager", status: true },
        { name: "Head", status: true },
        { name: "Working Committee", status: false },
      ],
    },
  ];

  const handleNavigate = () => {
    if (localStorage?.getItem("token") === "secretariat") {
      navigate("/secretariatCourseDetails");
    } else if (localStorage?.getItem("token") === "manager") {
      navigate("/managerViewDetails");
    } else if (localStorage?.getItem("token") === "head") {
      navigate("/headViewDetails");
    } else if (localStorage?.getItem("token") === "workingCommittee") {
      navigate("/wcViewDetails");
    }
  };

  const steps = [
    { name: "Secretariat", status: true },
    { name: "Manager", status: true },
    { name: "Head", status: false },
    { name: "Working Committee", status: false },
  ];

  return (
    <div>
      <div className="heading flex items-center justify-start mb-6">
        <div className="heading-icon text-sm w-9 h-9 rounded-md bg-blue-800 text-white flex items-center justify-center mr-2">
          <i className="fa-solid fa-house"></i>
        </div>
        <div className="text-md font-bold">Courses</div>
      </div>
      <div className="bg-white p-4 rounded-sm">
        <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
          Lists of Courses
        </div>
        <div className="data-table mt-5">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead style={{ backgroundColor: "#1e40af" }}>
                <TableRow
                  sx={{
                    "& th": {
                      color: "white",
                    },
                  }}>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Training Provider</TableCell>
                  <TableCell>Delivery Medium</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {courseLists.map((course) => (
                  <TableRow
                    key={course.courseName}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      "& td": {
                        padding: 3,
                      },
                    }}>
                    <TableCell
                      align="left"
                      style={{ color: "blue", cursor: "pointer" }}
                      onClick={handleNavigate}>
                      {course.courseName}
                    </TableCell>
                    <TableCell align="left">{course.trainingProvider}</TableCell>
                    <TableCell align="left">{course.deliveryMedium}</TableCell>
                    <TableCell align="left">
                      <Stepper nonLinear activeStep={1}>
                        {course.steps.map((label) => (
                          <Step
                            key={label.name}
                            completed={label.status}
                            disabled
                            sx={{
                              "& .MuiStepLabel-iconContainer .Mui-completed": {
                                color: "	#11823b",
                              },
                            }}>
                            <StepButton color="inherit">{label.name}</StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default SecretariatCourseLists;
