// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".zOYPN9APmdsS_t18HBsX {\r\n    background-color: #202fbb12;\r\n    min-height: calc(100vh - 104px);\r\n}", "",{"version":3,"sources":["webpack://./src/components/Layout.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,+BAA+B;AACnC","sourcesContent":[".LayoutContent {\r\n    background-color: #202fbb12;\r\n    min-height: calc(100vh - 104px);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"LayoutContent": "zOYPN9APmdsS_t18HBsX"
};
export default ___CSS_LOADER_EXPORT___;
