import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import styles from "./CourseDetails.module.css";
import DataTable from "./DataTable";
import { useNavigate } from "react-router-dom";

const CourseDetails = () => {
  const [age, setAge] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  return (
    <div>
      <div className="heading flex items-center justify-start mb-6">
        <div className="heading-icon text-sm w-9 h-9 rounded-md bg-blue-800 text-white flex items-center justify-center mr-2">
          <i className="fa-solid fa-house"></i>
        </div>
        <div className="text-md font-bold">Course Application</div>
      </div>
      <div className="content">
        <div className="bg-white rounded-sm p-4 mb-4">
          <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
            Search Course
          </div>
          <i>
            To perform other operations on a course, e.g a repeat, change, etc...
            please provide the criteria of the affected course and click Search
          </i>
          <div className="datafields-block mt-4">
            <div className="flex items-center mb-4">
              <div className="w-2/4 flex items-center justify-between mr-20">
                <div className="font-semibold mr-4">Course Code:</div>
                <div className="">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{
                      sx: {
                        padding: "10px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        padding: "0px",
                        top: "-6px",
                      },
                    }}
                  />
                </div>
              </div>
              <div className="w-2/4 flex items-center justify-between">
                <div className="font-semibold mr-4">Course Name:</div>
                <div className="">
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    inputProps={{
                      sx: {
                        padding: "10px",
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        padding: "0px",
                        top: "-6px",
                      },
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center mb-4">
              <div className="w-2/4 flex items-center justify-between mr-20">
                <div className="font-semibold mr-4">Course Date From:</div>
                <div className="">
                  <input
                    type="date"
                    className="p-2 border border-gray-300 w-[220px] rounded-md"
                  />
                </div>
              </div>
              <div className="w-2/4 flex items-center justify-between">
                <div className="font-semibold mr-4">Course Date To:</div>
                <div className="">
                  <input
                    type="date"
                    className="p-2 border border-gray-300 w-[220px] rounded-md"
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center mb-6">
              <div className="w-2/4 flex items-center justify-between pr-10">
                <div className="font-semibold">Course Status:</div>
                <div className="">
                  <FormControl sx={{ minWidth: 220 }}>
                    <InputLabel
                      id="demo-simple-select-helper-label"
                      className={styles.inputLabel}>
                      Course Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={age}
                      label="Course Medium"
                      onChange={handleChange}
                      classes={{ select: styles.select }}>
                      <MenuItem value="All">
                        <em>All</em>
                      </MenuItem>
                      <MenuItem value="Draft">Draft</MenuItem>
                      <MenuItem value="Pending">Pending</MenuItem>
                      <MenuItem value="Active">Active</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <Button variant="outlined" onClick={() => navigate("/newCourse")}>
                New Course
              </Button>
              <Button variant="outlined" onClick={() => setShowSearchResults(true)}>
                Search
              </Button>
            </div>
          </div>
        </div>
        {showSearchResults && (
          <div className="data-table p-4 bg-white">
            <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-6">
              Search Results
            </div>
            <DataTable />
          </div>
        )}
      </div>
    </div>
  );
};

export default CourseDetails;
