import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import styles from "./CourseDetails.module.css";
import { useNavigate } from "react-router-dom";
import AlertDialog from "./Dialog";

const NewCourse = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showNewDay, setShowNewDay] = useState(false);
  const [showNewTopic, setShowNewTopic] = useState(false);
  const [showTopicsCourse, setShowTopicsCourse] = useState(false);
  const [courseMedium, setCourseMedium] = useState("");
  const [courseType, setCourseType] = useState("");
  const [country, setCountry] = useState("");
  const [courseGroup, setCourseGroup] = useState("");
  const [showAddSpeaker, setShowAddSpeaker] = useState(false);
  const [personName, setPersonName] = React.useState<string[]>([]);
  const names = ["Alyz Rose", "Jeff Yap", "Farhan Zaini", "Mahen Mail", "RM Aziah"];
  const steps = ["Main", "Course Details", "Speakers", "Outline"];
  const [location, setLocation] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const navigate = useNavigate();
  const handleChange = (event: any) => {
    setCourseMedium(event.target.value);
  };

  const handleTypeChange = (event: any) => {
    setCourseType(event.target.value);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleSelectChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleSave = () => {
    navigate("/trainingProviderView");
  };

  return (
    <div>
      <div className="heading flex items-center justify-start mb-6">
        <div className="heading-icon text-sm w-9 h-9 rounded-md bg-blue-800 text-white flex items-center justify-center mr-2">
          <i className="fa-solid fa-house"></i>
        </div>
        <div className="text-md font-bold">Course Application</div>
      </div>
      <div className="content">
        <div className="tabs p-6 bg-white rounded-sm">
          <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep} className="mb-10">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    ".MuiStepIcon-root.Mui-active": {
                      color: "#1e40af",
                    },
                  }}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <div className="mb-4">
                <div className="mb-4">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    New Course
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">Course Medium</div>
                    <div className="w-3/4 flex items-center space-x-4">
                      <div>
                        <FormControl sx={{ minWidth: 300 }}>
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className={styles.inputLabel}>
                            Please Select a Course Medium
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={courseMedium}
                            label="Course Medium"
                            input={
                              <OutlinedInput label="Please Select a Course Medium" />
                            }
                            onChange={handleChange}
                            classes={{ select: styles.select }}>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="Instructor-Led">
                              Instructor Led / F2F
                            </MenuItem>
                            <MenuItem value="Online">Online</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div>
                        {courseMedium === "Online" && (
                          <FormControl sx={{ minWidth: 240 }}>
                            <InputLabel
                              id="demo-simple-select-helper-label"
                              className={styles.inputLabel}>
                              Please Select a Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={courseType}
                              label="Course Medium"
                              input={<OutlinedInput label="Please Select a Type" />}
                              onChange={handleTypeChange}
                              classes={{ select: styles.select }}>
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                              <MenuItem value="LiveConference">
                                Live / Conference
                              </MenuItem>
                              <MenuItem value="E-Learning">
                                E-learning / Pre-recording
                              </MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                  Course Applicataion Details
                </div>
                <div className="title-datafields">
                  {/* <div className="flex items-center mb-4">
                  <div className="field-title font-semibold w-1/4">
                    Application ID
                  </div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div> */}
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Application Type
                    </div>
                    <div className="w-3/4">Course - New</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      Base Course Name
                    </div>
                    <div className="w-3/4">N/A</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">Status</div>
                    <div className="w-3/4">Draft</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>
                      Reasons/Justifications
                    </div>
                    <div className="w-3/4">
                      <textarea className="border border-gray-300 rounded-md w-[300px]"></textarea>
                    </div>
                  </div>
                  {/* <div className="flex items-center mb-4">
                  <div className="field-title font-semibold w-1/4">
                    Base Course History
                  </div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div> */}
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      Date Approved
                    </div>
                    <div className="w-3/4">
                      <input
                        type="date"
                        className="p-2 border border-gray-300 w-[300px] rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <div className="course-details-tab mb-4">
                <div className="course-details mb-4">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Course Details
                  </div>
                  {/* <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">Course</div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">Category</div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">CPE Points</div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div> */}
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Delivery Medium</div>
                    <div className="w-3/4">Instructor Led Course</div>
                  </div>
                  {/* <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">Course Code</div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">
                    <span className="text-red-500 mr-1">*</span>Status
                  </div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div> */}
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Name
                    </div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Venue
                    </div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Course Country</div>
                    <div className="w-3/4">
                      <FormControl sx={{ minWidth: 240 }}>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className={styles.inputLabel}>
                          Please Select a Country
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={country}
                          label="Course Medium"
                          input={<OutlinedInput label="Please Select a Country" />}
                          onChange={(event) => setCountry(event.target.value)}
                          classes={{ select: styles.select }}>
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Malaysia">Malaysia</MenuItem>
                          <MenuItem value="Brunei">Brunei</MenuItem>
                          <MenuItem value="Indonesia">Indonesia</MenuItem>
                          <MenuItem value="Singapore">Singapore</MenuItem>
                          <MenuItem value="Thailand">Thailand</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  {country === "Malaysia" && (
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Course Location
                      </div>
                      <div className="w-3/4">
                        <FormControl sx={{ minWidth: 240 }}>
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className={styles.inputLabel}>
                            Please Select a Location
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={location}
                            label="Course Medium"
                            input={
                              <OutlinedInput label="Please Select a Location" />
                            }
                            onChange={(event) => setLocation(event.target.value)}
                            classes={{ select: styles.select }}>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="Kuching">Kuching</MenuItem>
                            <MenuItem value="Malacca">Malacca</MenuItem>
                            <MenuItem value="Ipoh">Ipoh</MenuItem>
                            <MenuItem value="Kuantan">Kuantan</MenuItem>
                            <MenuItem value="Miri">Miri</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  )}
                  <div className="flex my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Learning Outcome
                    </div>
                    <div className="w-3/4">
                      <div className="mb-2">
                        Learning Outcome: a statement on what course participants
                        should know, understand and can do upon completion of this
                        course.
                      </div>
                      <div>
                        <textarea className="border border-gray-300 rounded-md w-[240px]"></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Level
                    </div>
                    <div className=" flex w-3/4 space-x-4">
                      <div className="flex space-x-2">
                        <input type="checkbox" />
                        <label>Refresher</label>
                      </div>
                      <div className="flex space-x-2">
                        <input type="checkbox" />
                        <label>Intermediate</label>
                      </div>
                      <div className="flex space-x-2">
                        <input type="checkbox" />
                        <label>Advanced</label>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Target Audience
                    </div>
                    <div className="w-3/4">
                      <textarea className="border border-gray-300 rounded-md w-[240px]"></textarea>
                    </div>
                  </div>
                  <div className="flex my-4">
                    <div className="font-semibold w-1/4">Jointly-Organised?</div>
                    <div className="w-3/4">
                      <div>
                        <input type="checkbox" checked />
                      </div>
                      <div className="mb-2">Please state the parties involved</div>
                      <div>
                        <textarea className="border border-gray-300 rounded-md w-[240px]"></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Type
                    </div>
                    <div className=" flex w-3/4 space-x-4">
                      <div className="flex space-x-2">
                        <input type="checkbox" />
                        <label>In-House</label>
                      </div>
                      <div className="flex space-x-2">
                        <input type="checkbox" />
                        <label>Public</label>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">Remarks</div>
                    <div className="w-3/4">
                      <textarea className="border border-gray-300 rounded-md w-[240px]"></textarea>
                    </div>
                  </div>
                  <div className="flex my-2">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Industry Competency
                      Framework?
                    </div>
                    <div className="w-3/4">
                      <div>
                        <input type="checkbox" />
                      </div>
                      <div>
                        <textarea className="border border-gray-300 rounded-md w-[240px]"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="course-fees mb-4">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Course Fees
                  </div>
                  <div className="font-semibold mb-4">
                    <span className="text-red-500 mr-1">*</span>Proposed Fees and
                    Description
                  </div>
                  <div className="mb-4">
                    Enter proposed fee applicable for each group (e.g. members,
                    public, in-house etc.). If the course is free of charge, kindly
                    enter RMO for in-house.
                  </div>
                  <div className="flex items-center space-x-14">
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">RM</div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">For</div>
                      <div className="w-3/4">
                        <FormControl sx={{ minWidth: 240 }}>
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            className={styles.inputLabel}>
                            Please Select a Group
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={courseGroup}
                            label="Course Medium"
                            input={<OutlinedInput label="Please Select a Group" />}
                            onChange={(event) => setCourseGroup(event.target.value)}
                            classes={{ select: styles.select }}>
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value="members">Members</MenuItem>
                            <MenuItem value="public">Public</MenuItem>
                            <MenuItem value="in-house">In-House</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="flex space-x-2">
                      <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white px-4 py-2 rounded-md font-semibold">
                          Add
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white px-4 py-2 rounded-md font-semibold">
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="course-contact-details mb-4">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Contact Details
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Person(s)
                    </div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Address
                    </div>
                    <div className="w-3/4">
                      <textarea className="border border-gray-300 rounded-md w-[215px]"></textarea>
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Phone
                    </div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Fax
                    </div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Email
                    </div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">Website</div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <div className="course-speakers mb-4">
                <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                  Speakers
                </div>
                <div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">Speaker Name</div>
                    <div className="w-3/4">
                      <FormControl sx={{ m: 1, width: 300 }}>
                        <InputLabel
                          id="demo-multiple-checkbox-label"
                          className={styles.inputLabel}>
                          Select Speakers
                        </InputLabel>
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          multiple
                          value={personName}
                          onChange={handleSelectChange}
                          input={<OutlinedInput label="Select Speakers" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                          classes={{ select: styles.select }}>
                          {names.map((name) => (
                            <MenuItem key={name} value={name}>
                              <Checkbox checked={personName.indexOf(name) > -1} />
                              <ListItemText primary={name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md"
                      onClick={() => setShowAddSpeaker(true)}>
                      Add New Speaker
                    </button>
                  </div>
                </div>
                {showAddSpeaker ? (
                  <>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">NRIC</div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Salutation /
                        Title
                      </div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Speaker Name
                      </div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Curriculum Vitae
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="">
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            inputProps={{
                              sx: {
                                padding: "10px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                padding: "0px",
                                top: "-6px",
                              },
                            }}
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            Browse...
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            <i className="fa-solid fa-cloud-arrow-up mr-4"></i>Upload
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            <i className="fa-solid fa-trash mr-4"></i>Remove
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div>
                        You must use the following template file for upload. Please
                        download this file and fill it with your data before
                        uploading.
                      </div>
                      <div className="text-blue-500 font-semibold cursor-pointer mb-4">
                        Download Template
                      </div>
                      <div className="flex items-center space-x-2">
                        <div>
                          <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            Add
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md"
                            onClick={() => setShowAddSpeaker(false)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            )}
            {activeStep === 3 && (
              <div className="course-outline mb-4">
                <div className="mb-6">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Form Section
                  </div>
                  <div>
                    <button
                      className="bg-blue-800 text-white px-4 py-2 rounded-md font-semibold"
                      onClick={() => {
                        setShowNewDay(!showNewDay);
                        setShowTopicsCourse(false);
                      }}>
                      Add New Day
                      <i className="fa-regular fa-calendar-days ml-4"></i>
                    </button>
                  </div>
                  <div className="flex items-center space-x-2 mt-5">
                    <div className="date w-2/5">
                      <input
                        type="date"
                        className="p-2 border border-gray-300 w-full rounded-md"
                      />
                    </div>
                    <div className="hours w-2/5">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        placeholder="hours"
                        className="w-full"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                    <div className="ml-4">
                      <button
                        className="text-green-500 text-2xl ml-4"
                        onClick={() => setShowTopicsCourse(true)}>
                        <i className="fa-solid fa-check"></i>
                      </button>
                    </div>
                    <div className="ml-4">
                      <button
                        className="text-red-500 text-2xl ml-6"
                        onClick={() => setShowTopicsCourse(false)}>
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="topics-in-this-course">
                  <div className="mb-6">
                    <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                      Topics in this course
                    </div>
                    <div className="flex items-center justify-between">
                      <div>01/08/2023</div>
                      <div>1 hour(s)</div>
                      <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                          <i className="fa-regular fa-trash-can mr-4"></i>Remove Day
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-8 shadow-lg rounded-xl p-4">
                    <div className="w-3/4">
                      <div>
                        <div className="flex w-full items-center mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              1
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">8.00am - 9.00am</div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">Alyz Rose</li>
                                    <li className="text-sm">Jeff Yap</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-regular fa-pen-to-square"></i>
                                </button>
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6">
                    <div className="flex items-center justify-between mb-6">
                      <div>02/08/2023</div>
                      <div>2 hour(s)</div>
                      <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                          <i className="fa-regular fa-trash-can mr-4"></i>Remove Day
                        </button>
                      </div>
                    </div>
                    <div className="shadow-lg rounded-xl p-4">
                      <div className="w-3/4">
                        <div className="flex w-full items-center mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              1
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">
                                    10.00am - 11.00am
                                  </div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">Farhan Zaini</li>
                                    <li className="text-sm">Mahen Mail</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-regular fa-pen-to-square"></i>
                                </button>
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full items-center mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              2
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">1.00pm - 2.00pm</div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">RM Aziah</li>
                                    <li className="text-sm">Farhan Zaini</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-regular fa-pen-to-square"></i>
                                </button>
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <button
                      className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md"
                      onClick={() => setShowNewTopic(!showNewTopic)}>
                      Add New Topic
                    </button>
                  </div>
                  {showNewTopic && (
                    <div className="form-section mb-6">
                      <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                        Form Section
                      </div>
                      <div className="flex items-center my-2">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Topic
                        </div>
                        <div className="flex items-center space-x-2 w-3/4">
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            inputProps={{
                              sx: {
                                padding: "10px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                padding: "0px",
                                top: "-6px",
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex items-center my-2">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Description
                        </div>
                        <div className="w-3/4">
                          <textarea
                            className="border border-gray-300 rounded-md w-[214px]"
                            rows={2}></textarea>
                        </div>
                      </div>
                      <div className="flex items-center my-2">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Time
                        </div>
                        <div className="flex items-center w-3/4 space-x-2">
                          <div className="flex items-center space-x-1 mr-8">
                            <div className="w-10">
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                inputProps={{
                                  sx: {
                                    padding: "10px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    padding: "0px",
                                    top: "-6px",
                                  },
                                }}
                              />
                            </div>
                            <div>
                              <span>:</span>
                            </div>
                            <div className="w-10">
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                inputProps={{
                                  sx: {
                                    padding: "10px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    padding: "0px",
                                    top: "-6px",
                                  },
                                }}
                              />
                            </div>
                            <div className="w-10">
                              <FormControl sx={{ m: 1, minWidth: 40 }}>
                                <Select
                                  displayEmpty
                                  value={5}
                                  onChange={handleChange}
                                  classes={{ select: styles.select }}
                                  inputProps={{ "aria-label": "Without label" }}>
                                  <MenuItem value={5}>
                                    <em>AM</em>
                                  </MenuItem>
                                  <MenuItem value={10}>PM</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                          <div className="flex items-center space-x-1">
                            <div className="ml-4 mr-4">to</div>
                            <div className="w-10">
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                inputProps={{
                                  sx: {
                                    padding: "10px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    padding: "0px",
                                    top: "-6px",
                                  },
                                }}
                              />
                            </div>
                            <div>
                              <span>:</span>
                            </div>
                            <div className="w-10">
                              <TextField
                                id="outlined-basic"
                                variant="outlined"
                                inputProps={{
                                  sx: {
                                    padding: "10px",
                                  },
                                }}
                                InputLabelProps={{
                                  sx: {
                                    padding: "0px",
                                    top: "-6px",
                                  },
                                }}
                              />
                            </div>
                            <div className="w-10">
                              <FormControl sx={{ m: 1, minWidth: 40 }}>
                                <Select
                                  displayEmpty
                                  value={5}
                                  onChange={handleChange}
                                  classes={{ select: styles.select }}
                                  inputProps={{ "aria-label": "Without label" }}>
                                  <MenuItem value={5}>
                                    <em>AM</em>
                                  </MenuItem>
                                  <MenuItem value={10}>PM</MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex my-4">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Speaker (s)
                        </div>
                        <div className="w-3/4">
                          <div className="flex items-center space-x-2">
                            <div className="flex items-center">
                              <div className="border-2 w-72 h-32">
                                <div className="h-10 bg-blue-800 p-2">
                                  <span className="text-white text-sm">
                                    Speakers assigned to this topic
                                  </span>
                                </div>
                              </div>
                              <div className="w-52 flex flex-col items-center justify-between">
                                <div className="my-2 text-blue-800">
                                  {"<<Assign"}
                                </div>
                                <div className="my-2 text-blue-800">
                                  {"<<Assign as Backup"}
                                </div>
                                <div className="my-2  text-blue-800">
                                  {"Unassign>>"}
                                </div>
                              </div>
                              <div className="border-2 w-72 h-32">
                                <div className="h-10 bg-blue-800 p-2">
                                  <span className="text-white text-sm">
                                    Speakers registered with this course
                                  </span>
                                </div>
                                <ul className="ml-4 mt-2">
                                  <li className="italic text-sm mb-2">Alyz Rose</li>
                                  <li className="italic text-sm">Jeff Yap</li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div>
                          <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            Ok
                          </button>
                        </div>
                        <div>
                          <button
                            className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md"
                            onClick={() => setShowNewTopic(false)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="course-schedule mb-6">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Course Schedule
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">Upload Course Schedule</div>
                    <div className="flex items-center space-x-2">
                      <div className="mr-2">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                          Browse...
                        </button>
                      </div>
                      <div>
                        <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md w-full">
                          <i className="fa-solid fa-cloud-arrow-up mr-4"></i>Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex justify-end">
              <Button variant="outlined" className="mr-2" onClick={handleSave}>
                Save
              </Button>
              {activeStep > 0 && (
                <Button variant="outlined" className="mr-2" onClick={handleBackStep}>
                  Back
                </Button>
              )}
              {activeStep !== steps.length - 1 && (
                <Button variant="outlined" className="mr-2" onClick={handleNextStep}>
                  Next
                </Button>
              )}
              {activeStep === steps.length - 1 && (
                <Button
                  variant="outlined"
                  className="mr-2"
                  onClick={() => setShowAlert(true)}>
                  Submit
                </Button>
              )}
              <Button variant="outlined">Cancel</Button>
            </div>
          </Box>
        </div>
      </div>
      {showAlert ? (
        <AlertDialog
          open={showAlert}
          handleClose={() => setShowAlert(false)}
          handleSubmit={() => {
            setShowAlert(false);
            navigate("/trainingProviderView");
          }}
        />
      ) : null}
    </div>
  );
};

export default NewCourse;
