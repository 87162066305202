import { useState } from "react";
import user_logo from "../../public/logo.png";
import styles from "./Login.module.css";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleLogin = () => {
    if (email === "trainingprovider") {
      localStorage.setItem("token", "trainingprovider");
      navigate("/trainingProviderView");
    } else if (email === "secretariat") {
      localStorage.setItem("token", "secretariat");
      navigate("/secretariatView");
    } else if (email === "manager") {
      localStorage.setItem("token", "manager");
      navigate("/managerView");
    } else if (email === "head") {
      localStorage.setItem("token", "head");
      navigate("/headView");
    } else if (email === "workingcommittee") {
      localStorage.setItem("token", "workingCommittee");
      navigate("/wcView");
    }
  };

  return (
    <div>
      <div className="flex items-center justify-center h-screen">
        <div className="sm:flex sm:h-[400px] sm:w-[700px] w-full shadow-lg">
          <div className="bg-blue-800 h-[75px] sm:h-[400px] sm:w-[300px] w-full p-5">
            <div className="text-white flex items-center justify-center sm:text-2xl text-xl font-semibold">
              Welcome to the CPE Tracker System
            </div>
          </div>
          <div
            className={`${styles.loginLayout} h-[400px] sm:w-[400px] w-full p-5 flex flex-col items-center justify-center`}>
            <div className="w-[200px] ml-32">
              <img src={user_logo} className="h-[70px]" />
            </div>
            <div className="text-slate-700 flex items-center justify-center text-xl font-semibold mt-1">
              Login To Your Account
            </div>
            <div className="flex flex-col gap-4 items-center justify-center mt-5">
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className="h-10 w-[300px] p-2 text-sm rounded-sm border-2"
                />
              </div>
              {/* <div>
                <input
                  type="text"
                  name="loginId"
                  placeholder="LoginID"
                  className="h-10 w-[300px] p-2 text-sm rounded-sm border-2"
                />
              </div> */}
              <div>
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  className="h-10 w-[300px] p-2 text-sm rounded-sm border-2"
                />
              </div>
            </div>
            <div className="flex items-center justify-center mt-5">
              <button
                className="bg-blue-800 text-white h-10 w-[300px] font-semibold rounded-sm"
                onClick={handleLogin}>
                Log In
              </button>
            </div>
            <div className="mt-2">
              <span className="text-sm text-blue-800 italic cursor-pointer">
                Forgot password?
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
