// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ByAuFr6UCIITa4PX9wwQ {\r\n    top: -6px;\r\n}\r\n.A21j0XNbPvYByJaQSMHx {\r\n    padding: 10px;\r\n}\r\n.D3qB5fdwFT5mIjw4UGep {\r\n    padding: 4px;\r\n}", "",{"version":3,"sources":["webpack://./src/components/CourseDetails.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb;AACA;IACI,aAAa;AACjB;AACA;IACI,YAAY;AAChB","sourcesContent":[".inputLabel {\r\n    top: -6px;\r\n}\r\n.select {\r\n    padding: 10px;\r\n}\r\n.jj {\r\n    padding: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputLabel": "ByAuFr6UCIITa4PX9wwQ",
	"select": "A21j0XNbPvYByJaQSMHx",
	"jj": "D3qB5fdwFT5mIjw4UGep"
};
export default ___CSS_LOADER_EXPORT___;
