import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import styles from "./CourseDetails.module.css";
import { useNavigate } from "react-router-dom";
import AlertDialog from "./Dialog";

const SecretariatCourseDetails = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [showNewDay, setShowNewDay] = useState(false);
  const [showSpeakerForm, setShowSpeakerForm] = useState(false);
  const [showNewTopicForm, setShowNewTopicForm] = useState(false);
  const [showTopicsCourse, setShowTopicsCourse] = useState(false);
  const [category, setCategory] = useState<any>("");
  const [subCategory, setSubCategory] = useState("");
  const [showSpeakerDetails, setShowSpeakerDetails] = useState(false);
  const [status, setStatus] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  const steps = [
    "Main",
    "Course Details",
    "Speakers",
    "Outline",
    "Recommendation",
    "Decision",
  ];

  const rows = [
    {
      submissionDate: "15 JAN 2004",
      submissionRivisionNo: 1221,
      submissionBy: "Rosma (TP)",
      submissionReason: "Applying for new course",
      resultDate: "",
      resultRivisionNo: "",
      resultsBy: "",
      resultsRemarks: "",
      status: "Pending",
    },
  ];

  const speakerRows = [
    {
      name: "Alyz Rose",
      biodata: "View",
      passportValue: "666666-66-6666",
    },
  ];

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value);
  };

  const handleChangeSubCategory = (event: SelectChangeEvent) => {
    setSubCategory(event.target.value);
  };

  const token = localStorage?.getItem("token");

  const handleSave = () => {
    if (token === "secretariat") {
      navigate("/secretariatView");
    } else if (token === "head") {
      navigate("/headView");
    } else if (token === "manager") {
      navigate("/managerView");
    } else if (token === "workingCommittee") {
      navigate("/wcView");
    }
  };

  return (
    <div>
      <div className="heading flex items-center justify-start mb-6">
        <div className="heading-icon text-sm w-9 h-9 rounded-md bg-blue-800 text-white flex items-center justify-center mr-2">
          <i className="fa-solid fa-house"></i>
        </div>
        <div className="text-md font-bold">Course Application</div>
      </div>
      <div className="content">
        <div className="tabs p-6 bg-white rounded-sm">
          <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep} className="mb-10">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    ".MuiStepIcon-root.Mui-active": {
                      color: "#1e40af",
                    },
                  }}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            {activeStep === 0 && (
              <div className="mb-4">
                <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                  Course Applicataion Details
                </div>
                <div className="title-datafields mb-6">
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      Application ID
                    </div>
                    <div className="w-3/4">372</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Application Type
                    </div>
                    <div className="w-3/4">Course - New</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      Base Course Name
                    </div>
                    <div className="w-3/4">N/A</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">Status</div>
                    <div className="w-3/4">Pending</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>
                      Reasons/Justifications
                    </div>
                    <div className="w-3/4">Applying for new course</div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      Base Course History
                    </div>
                    <div className="w-3/4">
                      <a className="italic text-blue-800 underline cursor-pointer">
                        Click here to view
                      </a>
                    </div>
                  </div>
                  <div className="flex items-center mb-4">
                    <div className="field-title font-semibold w-1/4">
                      Date Approved
                    </div>
                    <div className="w-3/4">01/08/2023</div>
                  </div>
                </div>
                <div className="mb-4">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Application History
                  </div>
                  <div className="data-table">
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#1e40af" }}>
                          <TableRow
                            sx={{
                              "& th": {
                                color: "white",
                              },
                            }}>
                            <TableCell align="center">Submission Date</TableCell>
                            <TableCell align="center">
                              Submission Rivision No.
                            </TableCell>
                            <TableCell align="center">Submission By</TableCell>
                            <TableCell align="center">Submission Reasons</TableCell>
                            <TableCell align="center">Result Date</TableCell>
                            <TableCell align="center">
                              Results Rivision No.
                            </TableCell>
                            <TableCell align="center">Results By</TableCell>
                            <TableCell align="center">Results Remarks</TableCell>
                            <TableCell align="center">Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.submissionRivisionNo}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}>
                              <TableCell
                                style={{ color: "blue", cursor: "pointer" }}
                                // onClick={() =>
                                //   navigate("/secretariatCourseDetails")
                                // }
                              >
                                {row.submissionDate}
                              </TableCell>
                              <TableCell>{row.submissionRivisionNo}</TableCell>
                              <TableCell>{row.submissionBy}</TableCell>
                              <TableCell>{row.submissionReason}</TableCell>
                              <TableCell>{row.resultDate}</TableCell>
                              <TableCell>{row.resultRivisionNo}</TableCell>
                              <TableCell>{row.resultsBy}</TableCell>
                              <TableCell>{row.resultsRemarks}</TableCell>
                              <TableCell>{row.status}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 1 && (
              <div className="course-details-tab mb-4">
                <div className="course-details mb-6">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Course History
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">History</div>
                    <div className="w-3/4 text-blue-800 underline cursor-pointer">
                      View
                    </div>
                  </div>
                </div>
                <div className="course-details mb-4">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Course Details
                  </div>
                  {/* <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">Course Category</div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div> */}
                  {/* <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">CPE Points</div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div> */}
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Course Category</div>
                    <div className="w-3/4">
                      Capital Market Regulation - Risk Management
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">CPE Points</div>
                    <div className="w-3/4">10</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Delivery Medium</div>
                    <div className="w-3/4">Instructor Led Course</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Course Code</div>
                    <div className="w-3/4">SIDC-0102-E</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Status
                    </div>
                    <div className="w-3/4">
                      <FormControl sx={{ minWidth: 220 }}>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className={styles.inputLabel}>
                          Course Status
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={status}
                          label="Course Medium"
                          onChange={(e) => setStatus(e.target.value)}
                          classes={{ select: styles.select }}>
                          <MenuItem value="All">
                            <em>All</em>
                          </MenuItem>
                          <MenuItem value="Draft">Draft</MenuItem>
                          <MenuItem value="Pending">Pending</MenuItem>
                          <MenuItem value="Active">Active</MenuItem>
                          <MenuItem value="Completed">Completed</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="flex my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Reason
                    </div>
                    <div className="w-3/4">
                      <textarea className="border border-gray-300 rounded-md w-[300px]"></textarea>
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Name
                    </div>
                    <div className="w-3/4">
                      Insights into Foreign Exchange Policy (FEP)
                    </div>
                  </div>
                  {/* <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">
                    <span className="text-red-500 mr-1">*</span>Course Venue
                  </div>
                  <div className="w-3/4">
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      inputProps={{
                        sx: {
                          padding: "10px",
                        },
                      }}
                      InputLabelProps={{
                        sx: {
                          padding: "0px",
                          top: "-6px",
                        },
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">
                    <span className="text-red-500 mr-1">*</span>Course Location
                  </div>
                  <div className="w-3/4">
                    <FormControl sx={{ minWidth: 240 }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Please Select a Country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={""}
                        label="Course Medium"
                        onChange={handleChange}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div> */}
                  <div className="flex my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Objectives
                    </div>
                    <div className="w-3/4">
                      <div>
                        1. Recognise the legal framework and signified rules in the
                        Foreign Exchange Notices
                      </div>
                      <div>
                        2. Identify the legal provisions relating to the FEP under
                        Financial Services Act 2013
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Level
                    </div>
                    <div className="w-3/4">Intermediate</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Target Audience
                    </div>
                    <div className="w-3/4">
                      Compliance, dealing and management team of iFAST capital Sdn
                      Bhd
                    </div>
                  </div>
                  <div className="flex my-4">
                    <div className="font-semibold w-1/4">Jointly-Organised?</div>
                    <div className="w-3/4">No</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Course Type
                    </div>
                    <div className="w-3/4">In-house</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Remarks</div>
                    <div className="w-3/4">
                      <div>Duration - 3.5 hours</div>
                      <div>Type - SIDC LMS</div>
                    </div>
                  </div>
                </div>
                <div className="course-fees mb-6">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Course Fees
                  </div>
                  <div className="font-semibold mb-4">
                    <span className="text-red-500 mr-1">*</span>Proposed Fees and
                    Description
                  </div>
                  {/* <div>
                    Enter proposed fee applicable for each group (e.g. members,
                    public, in-house etc.). If the course is free of charge, kindly
                    enter RMO for in-house.
                  </div> */}
                  <div className="my-4">
                    <div className="flex items-center space-x-96 my-4">
                      <div className="w-2/4">
                        <span className="font-bold">RM 0</span> for members of
                        PERSAMA
                      </div>
                      {/* <div className="flex items-center space-x-2 w-3/4">
                        <div>
                          <button className="mr-4">
                            <i className="fa-solid fa-pencil"></i>
                          </button>
                        </div>
                        <div>
                          <button className="">
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                    <div className="flex items-center space-x-96 my-4">
                      <div className="w-2/4">
                        <span className="font-bold">RM 200</span> for public
                      </div>
                      {/* <div className="flex items-center space-x-2 w-3/4">
                        <div>
                          <button className="mr-4">
                            <i className="fa-solid fa-pencil"></i>
                          </button>
                        </div>
                        <div>
                          <button className="">
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                    <div className="flex items-center space-x-96 my-4">
                      <div className="w-2/4">
                        <span className="font-bold">RM 150</span> for students
                      </div>
                      {/* <div className="flex items-center space-x-2 w-3/4">
                        <div>
                          <button className="mr-4">
                            <i className="fa-solid fa-pencil"></i>
                          </button>
                        </div>
                        <div>
                          <button className="">
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                    <div className="flex items-center space-x-96 my-4">
                      <div className="w-2/4">
                        <span className="font-bold">RM 100</span> for other licences
                      </div>
                      {/* <div className="flex items-center space-x-2 w-3/4">
                        <div>
                          <button className="mr-4">
                            <i className="fa-solid fa-pencil"></i>
                          </button>
                        </div>
                        <div>
                          <button className="">
                            <i className="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="flex items-center space-x-4">
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">RM</div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">For</div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                        Add
                      </button>
                    </div>
                    <div>
                      <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                        Cancel
                      </button>
                    </div>
                  </div> */}
                </div>
                <div className="course-contact-details mb-4">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Contact Details
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Person(s)
                    </div>
                    <div className="w-3/4">Person1</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Address
                    </div>
                    <div className="w-3/4">Address1</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Phone
                    </div>
                    <div className="w-3/4">8888888888</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Fax
                    </div>
                    <div className="w-3/4">212-693-2345</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">
                      <span className="text-red-500 mr-1">*</span>Contact Email
                    </div>
                    <div className="w-3/4">person@gmail.com</div>
                  </div>
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Website</div>
                    <div className="w-3/4">www.person.com</div>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 2 && (
              <div>
                <div className="mb-6">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Speaker(s) for this course
                  </div>
                  <div className="data-table">
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table">
                        <TableHead style={{ backgroundColor: "#1e40af" }}>
                          <TableRow
                            sx={{
                              "& th": {
                                color: "white",
                              },
                            }}>
                            <TableCell>Name</TableCell>
                            <TableCell>NRIC / Passport / SpecialId</TableCell>
                            <TableCell>Biodata</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {speakerRows.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{
                                "&:last-child td, &:last-child th": { border: 0 },
                              }}>
                              <TableCell
                                style={{ color: "blue", cursor: "pointer" }}>
                                {row.name}
                              </TableCell>
                              <TableCell>{row.passportValue}</TableCell>
                              <TableCell
                                style={{ color: "blue", cursor: "pointer" }}
                                onClick={() => setShowSpeakerDetails(true)}>
                                {row.biodata}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                {showSpeakerDetails && (
                  <div className="course-speakers mb-4">
                    <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                      Speaker View Details
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">NRIC</div>
                      <div className="w-3/4">666666-66-6666</div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Salutation /
                        Title
                      </div>
                      <div className="w-3/4">Title</div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Speaker Name
                      </div>
                      <div className="w-3/4">Alyz Rose</div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1 cursor-pointer">*</span>
                        Curriculum Vitae
                      </div>
                      <div className="flex items-center space-x-2 w-3/4">
                        <a className="text-blue-800 underline">
                          <i>Click here to view the CV</i>
                        </a>
                      </div>
                    </div>
                    {/* <div className="mb-4">
                      <button
                        className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md"
                        onClick={() => setShowSpeakerForm(!showSpeakerForm)}>
                        Add New Speaker
                      </button>
                    </div> */}
                  </div>
                )}
                {showSpeakerForm ? (
                  <div className="course-speakers mb-4">
                    <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                      Speaker Form
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">NRIC</div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Salutation /
                        Title
                      </div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Speaker Name
                      </div>
                      <div className="w-3/4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="flex items-center my-2">
                      <div className="font-semibold w-1/4">
                        <span className="text-red-500 mr-1">*</span>Curriculum Vitae
                      </div>
                      <div className="flex items-center space-x-2 w-3/4">
                        <div>
                          <TextField
                            id="outlined-basic"
                            variant="outlined"
                            inputProps={{
                              sx: {
                                padding: "10px",
                              },
                            }}
                            InputLabelProps={{
                              sx: {
                                padding: "0px",
                                top: "-6px",
                              },
                            }}
                          />
                        </div>
                        <div>
                          <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            Browse...
                          </button>
                        </div>
                        <div>
                          <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            <i className="fa-solid fa-cloud-arrow-up mr-4"></i>Upload
                          </button>
                        </div>
                        <div>
                          <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            <i className="fa-solid fa-trash mr-4"></i>Remove
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <div className="mb-2">
                        You must use the following template file for upload. Please
                        download this file and fill it with your data before
                        uploading.
                      </div>
                      <div className="text-blue-500 font-semibold cursor-pointer mb-2">
                        Download Template
                      </div>
                      <div className="flex items-center space-x-2">
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                            Update
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md"
                            onClick={() => setShowSpeakerForm(false)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>{" "}
                  </div>
                ) : null}
              </div>
            )}
            {activeStep === 3 && (
              <div className="course-outline mb-4">
                <div className="mb-6">
                  {/* <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Form Section
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="bg-blue-800 text-white h-10 w-[200px] font-semibold rounded-sm"
                      onClick={() => setShowNewDay(!showNewDay)}>
                      Add New Day
                    </button>
                  </div> */}
                  {/* {showNewDay && (
                    <div className="flex items-center space-x-2 mt-5">
                      <div>
                        <select name="selectedDay" className="border-2">
                          <option value="">Day</option>
                        </select>
                      </div>
                      <div>
                        <select name="selectedMonth" className="border-2">
                          <option value="">Month</option>
                        </select>
                      </div>
                      <div>
                        <input type="text" className="border-2 w-20" />
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white h-8 w-[100px] font-semibold rounded-sm"
                          onClick={() => setShowTopicsCourse(true)}>
                          Ok
                        </button>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white h-8 w-[100px] font-semibold rounded-sm"
                          onClick={() => setShowTopicsCourse(false)}>
                          Cancel
                        </button>
                      </div>
                    </div>
                  )} */}
                </div>
                <div className="topics-in-this-course">
                  <div className="mb-8">
                    <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                      Topics in this course
                    </div>
                    <div className="flex items-center justify-between">
                      <div>01/08/2023</div>
                      <div>2 hour(s)</div>
                      {/* <div>
                        <button
                          type="submit"
                          className="bg-blue-800 text-white h-10 w-[200px] font-semibold rounded-sm">
                          Remove Day
                        </button>
                      </div> */}
                    </div>
                  </div>
                  <div className="mb-8 shadow-lg rounded-xl p-4">
                    <div className="w-3/4">
                      <div>
                        <div className="flex w-full items-center mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              1
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">8.00am - 9.00am</div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">Alyz Rose</li>
                                    <li className="text-sm">Jeff Yap</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6">
                    <div className="flex items-center justify-between mb-6">
                      <div>02/08/2023</div>
                      <div>2 hour(s)</div>
                    </div>
                    <div className="shadow-lg rounded-xl p-4">
                      <div className="w-3/4">
                        <div className="flex w-full items-center mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              1
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">
                                    10.00am - 11.00am
                                  </div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">Farhan Zaini</li>
                                    <li className="text-sm">Mahen Mail</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full items-center mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              2
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">1.00pm - 2.00pm</div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">RM Aziah</li>
                                    <li className="text-sm">Farhan Zaini</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="mb-6">
                    <div className="drop-shadow-md rounded-lg w-3/4">
                      <div>
                        <div className="flex w-full items-center shadow-sm rounded-2xl mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              1
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">8.00am - 9.00am</div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">speaker 1</li>
                                    <li className="text-sm">speaker 2</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full items-center shadow-sm rounded-2xl mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              2
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">8.00am - 9.00am</div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">speaker 1</li>
                                    <li className="text-sm">speaker 2</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex w-full items-center shadow-sm rounded-2xl mb-6 p-4">
                          <div className="w-24 h-full flex justify-center items-center">
                            <div className="w-16 h-16 text-2xl rounded-full flex justify-center items-center text-blue-800 shadow-lg">
                              3
                            </div>
                          </div>
                          <div className="w-full ml-5">
                            <div className="content-list">
                              <div className="flex justify-normal mb-4">
                                <div className="left-list pr-4">
                                  <div className="font-bold mb-1">
                                    Assessing corporate strategic
                                  </div>
                                  <div className="text-sm mb-1">8.00am - 9.00am</div>
                                  <div className="text-sm italic">
                                    - Assessing strategic capability. Taking action
                                    to add value. Finding information on company and
                                    their markets.
                                  </div>
                                </div>
                                <div className="right-list w-1/3">
                                  <div className="text-sm font-semibold mb-4">
                                    Speaker(s)
                                  </div>
                                  <ul className="list-disc ml-8">
                                    <li className="text-sm">speaker 1</li>
                                    <li className="text-sm">speaker 2</li>
                                  </ul>
                                </div>
                              </div>
                              <div className="flex items-center justify-end space-x-2">
                                <button
                                  type="submit"
                                  className="w-10 h-10 text-black border border-b-4 border-black rounded-md">
                                  <i className="fa-solid fa-eye"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {showNewTopicForm ? (
                    <div className="form-section mb-6">
                      <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                        Form Section
                      </div>
                      <div className="flex items-center my-2">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Topic
                        </div>
                        <div className="flex items-center space-x-2">
                          <div className="w-3/4">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              inputProps={{
                                sx: {
                                  padding: "10px",
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  padding: "0px",
                                  top: "-6px",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center my-2">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Description
                        </div>
                        <div className="w-3/4">
                          <textarea className="border-2 w-[300px]"></textarea>
                        </div>
                      </div>
                      <div className="flex items-center my-2">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Time
                        </div>
                        <div className="flex items-center w-3/4 space-x-2">
                          <div className="flex items-center space-x-1">
                            <div>
                              <input type="text" className="border-2 w-10" />
                            </div>
                            <div>
                              <span>:</span>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={"00"}
                                className="border-2 w-10"
                              />
                            </div>
                            <div>
                              <select name="selectedTime" className="border-2">
                                <option value="">AM</option>
                              </select>
                            </div>
                          </div>
                          <div className="flex items-center space-x-1">
                            <div>to</div>
                            <div>
                              <input type="text" className="border-2 w-10" />
                            </div>
                            <div>
                              <span>:</span>
                            </div>
                            <div>
                              <input
                                type="text"
                                value={"00"}
                                className="border-2 w-10"
                              />
                            </div>
                            <div>
                              <select name="selectedTime" className="border-2">
                                <option value="">AM</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex my-2">
                        <div className="font-semibold w-1/4">
                          <span className="text-red-500 mr-1">*</span>Speaker (s)
                        </div>
                        <div className="w-3/4">
                          <div className="flex items-center space-x-2">
                            <div className="flex items-center">
                              <div className="border-2 w-72 h-32">
                                <div className="h-10 bg-blue-800 p-2">
                                  <span className="text-white text-sm">
                                    Speakers assigned to this topic
                                  </span>
                                </div>
                              </div>
                              <div className="w-52 mr-10">
                                <div className="my-2 ml-44 text-blue-800">
                                  {"<<Assign"}
                                </div>
                                <div className="my-2 ml-16 text-blue-800">
                                  {"<<Assign as Backup"}
                                </div>
                                <div className="my-2 mr-20 text-blue-800">
                                  {"Unassign>>"}
                                </div>
                              </div>
                              <div className="border-2 w-72 h-32">
                                <div className="h-10 bg-blue-800 p-2">
                                  <span className="text-white text-sm">
                                    Speakers registered with this course
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white h-8 w-[100px] font-semibold rounded-sm">
                            Ok
                          </button>
                        </div>
                        <div>
                          <button
                            type="submit"
                            className="bg-blue-800 text-white h-8 w-[100px] font-semibold rounded-sm"
                            onClick={() => setShowNewTopicForm(false)}>
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {/* <div className="course-schedule mb-6">
                  <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                    Course Schedule
                  </div>
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">Upload Course Schedule</div>
                    <div className="flex items-center space-x-2">
                      <div className="mr-4">
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              padding: "10px",
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              padding: "0px",
                              top: "-6px",
                            },
                          }}
                        />
                      </div>
                      <div>
                        <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md">
                          Browse...
                        </button>
                      </div>
                      <div>
                        <button className="bg-blue-800 text-white px-4 py-2 font-semibold rounded-md w-full">
                          <i className="fa-solid fa-cloud-arrow-up mr-4"></i>Upload
                        </button>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            )}
            {activeStep === 4 && (
              <div className="recommendation mb-4">
                <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                  Recommendation
                </div>
                {token === "secretariat" && (
                  <div className="flex items-center my-2">
                    <div className="font-semibold w-1/4">Proposed CPE points</div>
                    <div className="w-3/4">
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        inputProps={{
                          sx: {
                            padding: "10px",
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            padding: "0px",
                            top: "-6px",
                          },
                        }}
                      />
                    </div>
                  </div>
                )}
                <div className="flex items-center my-4">
                  <div className="font-semibold w-1/4">Recommendation</div>
                  <div className="w-3/4 flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                      <div className="">
                        <input type="radio" name="active" />
                      </div>
                      <div className="font-semibold">Approve</div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="">
                        <input type="radio" name="active" />
                      </div>
                      <div className="font-semibold">Not Approve</div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="">
                        <input type="radio" name="active" />
                      </div>
                      <div className="font-semibold">KIV</div>
                    </div>
                  </div>
                </div>
                {token === "secretariat" && (
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Course Category</div>
                    <div className="w-3/4">
                      <FormControl sx={{ minWidth: 240 }}>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          className={styles.inputLabel}>
                          Category
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={category}
                          label="Course Medium"
                          onChange={handleChange}
                          classes={{ select: styles.select }}>
                          <MenuItem value={10}>Capital Market Regulation</MenuItem>
                          <MenuItem value={20}>Capital Market Development</MenuItem>
                          <MenuItem value={30}>Behavioural</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                )}
                {category && (
                  <div className="flex items-center my-4">
                    <div className="font-semibold w-1/4">Sub Course Category</div>
                    <div className="w-3/4">
                      <FormControl sx={{ minWidth: 240 }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Sub Category
                        </InputLabel>
                        {category === 10 ? (
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={subCategory}
                            label="Course Medium"
                            onChange={handleChangeSubCategory}>
                            <MenuItem value={10}>Laws and Regulation</MenuItem>
                            <MenuItem value={20}>Corporate Governance</MenuItem>
                            <MenuItem value={30}>Risk Management</MenuItem>
                          </Select>
                        ) : category === 20 ? (
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={subCategory}
                            label="Course Medium"
                            onChange={handleChangeSubCategory}>
                            <MenuItem value={10}>
                              Economics and Financial Environments
                            </MenuItem>
                            <MenuItem value={20}>
                              Investment Products, Management and Valuation
                            </MenuItem>
                            <MenuItem value={30}>
                              Capital Raising, Strategic Management and Corporate
                              Finance
                            </MenuItem>
                            <MenuItem value={30}>
                              Investors and Shareholders
                            </MenuItem>
                          </Select>
                        ) : (
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={subCategory}
                            label="Course Medium"
                            onChange={handleChangeSubCategory}>
                            <MenuItem value={10}>Self- Management</MenuItem>
                            <MenuItem value={20}>People Management</MenuItem>
                            <MenuItem value={30}>Organisation Leadership</MenuItem>
                          </Select>
                        )}
                      </FormControl>
                    </div>
                  </div>
                )}
                <div className="flex items-center my-2">
                  <div className="font-semibold w-1/4">Justification</div>
                  <div className="w-3/4">
                    <textarea className="border border-gray-300 rounded-md w-[240px]"></textarea>
                  </div>
                </div>
              </div>
            )}
            {activeStep === 5 && (
              <div className="decision mb-4">
                <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                  Processing Decision
                </div>
                {(token === "manager" ||
                  token === "workingCommittee" ||
                  token === "head") && (
                  <div className="mb-6 shadow-md p-5">
                    <div className="font-semibold underline italic text-blue-800">
                      Remarks from Secretraiat:
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Course Category</div>
                      <div className="w-3/4 flex items-center space-x-4">
                        Capital Market Regulation
                      </div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Proposed CPE points</div>
                      <div className="w-3/4 flex items-center space-x-4">22</div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Action</div>
                      <div className="w-3/4 flex items-center space-x-4">
                        Approved
                      </div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Remarks</div>
                      <div className="w-3/4">
                        <div>Duration - 3.5 hours</div>
                        <div> Type - SIDC LMS</div>
                      </div>
                    </div>
                  </div>
                )}
                {(token === "head" || token === "workingCommittee") && (
                  <div className="mb-6 shadow-md p-5">
                    <div className="font-semibold underline italic text-blue-800">
                      Remarks from Manager:
                    </div>
                    {/* <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Course Category</div>
                      <div className="w-3/4 flex items-center space-x-4">
                        Capital Market Regulation
                      </div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Proposed CPE points</div>
                      <div className="w-3/4 flex items-center space-x-4">22</div>
                    </div> */}
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Action</div>
                      <div className="w-3/4 flex items-center space-x-4">
                        Approved
                      </div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Remarks</div>
                      <div className="w-3/4">
                        <div>Duration - 3.5 hours</div>
                        <div> Type - SIDC LMS</div>
                      </div>
                    </div>
                  </div>
                )}
                {token === "workingCommittee" && (
                  <div className="mb-6 shadow-md p-5">
                    <div className="font-semibold underline italic text-blue-800">
                      Remarks from Head:
                    </div>
                    {/* <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Course Category</div>
                      <div className="w-3/4 flex items-center space-x-4">
                        Capital Market Regulation
                      </div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Proposed CPE points</div>
                      <div className="w-3/4 flex items-center space-x-4">22</div>
                    </div> */}
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Action</div>
                      <div className="w-3/4 flex items-center space-x-4">
                        Approved
                      </div>
                    </div>
                    <div className="flex items-center my-4">
                      <div className="font-semibold w-1/4">Remarks</div>
                      <div className="w-3/4">
                        <div>Duration - 3.5 hours</div>
                        <div> Type - SIDC LMS</div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex items-center my-4">
                  <div className="font-semibold w-1/4">Action</div>
                  <div className="w-3/4 flex items-center space-x-4">
                    <div className="flex items-center space-x-2">
                      <div className="">
                        <input type="radio" name="active" />
                      </div>
                      <div className="font-semibold">Approve</div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="">
                        <input type="radio" name="active" />
                      </div>
                      <div className="font-semibold">Not Approve</div>
                    </div>
                    <div className="flex items-center space-x-2">
                      <div className="">
                        <input type="radio" name="active" />
                      </div>
                      <div className="font-semibold">Return</div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center my-4">
                  <div className="font-semibold w-1/4">Remarks</div>
                  <div className="w-3/4">
                    <textarea className="border border-gray-300 rounded-md w-[240px]"></textarea>
                  </div>
                </div>
              </div>
            )}
            <div
              className={`flex ${
                activeStep === steps.length - 1 ? "justify-between" : "justify-end"
              }`}>
              {activeStep === steps.length - 1 && (
                <div className="left-buttons-group">
                  <Button variant="outlined" onClick={() => setShowAlert(true)}>
                    Process
                  </Button>
                </div>
              )}
              <div className="right-buttons-group">
                <Button variant="outlined" className="mr-2" onClick={handleSave}>
                  Save
                </Button>
                {activeStep > 0 && (
                  <Button
                    variant="outlined"
                    className="mr-2"
                    onClick={handleBackStep}>
                    Back
                  </Button>
                )}
                {activeStep !== steps.length - 1 && (
                  <Button
                    variant="outlined"
                    className="mr-2"
                    onClick={handleNextStep}>
                    Next
                  </Button>
                )}
                <Button variant="outlined">Cancel</Button>
              </div>
            </div>
          </Box>
        </div>
      </div>
      {showAlert ? (
        <AlertDialog
          open={showAlert}
          handleClose={() => setShowAlert(false)}
          handleSubmit={() => {
            setShowAlert(false);
            if (token === "secretariat") {
              navigate("/secretariatView");
            } else if (token === "head") {
              navigate("/headView");
            } else if (token === "manager") {
              navigate("/managerView");
            } else if (token === "workingCommittee") {
              navigate("/wcView");
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default SecretariatCourseDetails;
