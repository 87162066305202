import { Outlet } from "react-router-dom";
import Header from "./Header";
import styles from "./Layout.module.css"

const Layout = () => {
  return (
    <div className="Layout-container">
      <Header />
      <div className={`${styles.LayoutContent} px-28 py-6`}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
