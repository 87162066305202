import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const EvaluationReport = () => {
  const navigate = useNavigate();
  const [showSearchResults, setShowSearchResults] = useState(false);
  const rows = [
    {
      name: "Securities Regulation Professional Conduct and Ethics",
      courseCode: "SIDC-0001-010",
      courseStartDate: "20 OCT 2003",
      courseEndDate: "22 OCT 2003",
      status: "Conducted",
    },
  ];
  return (
    <div>
      <div className="heading flex items-center justify-start mb-6">
        <div className="heading-icon text-sm w-9 h-9 rounded-md bg-blue-800 text-white flex items-center justify-center mr-2">
          <i className="fa-solid fa-house"></i>
        </div>
        <div className="text-md font-bold">Evaluation Report</div>
      </div>
      <div className="content">
        <div className="bg-white rounded-sm mb-4 p-4">
          <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
            Search Course
          </div>
          <div className="flex items-center my-2">
            <div className="font-semibold w-1/4">Course Code</div>
            <div className="flex items-center space-x-2 w-3/4">
              <div>
                <input type="text" className="border-2 w-20" />
              </div>
              <div>-</div>
              <div>
                <input type="text" className="border-2 w-20" />
              </div>
              <div>-</div>
              <div>
                <input type="text" className="border-2 w-20" />
              </div>
            </div>
          </div>
          <div className="flex items-center my-2">
            <div className="font-semibold w-1/4">Course Name</div>
            <div className="w-3/4">
              <TextField
                id="outlined-basic"
                variant="outlined"
                inputProps={{
                  sx: {
                    padding: "10px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    padding: "0px",
                    top: "-6px",
                  },
                }}
              />
            </div>
          </div>
          <div className="flex items-center my-2">
            <div className="font-semibold w-1/4">Course Date</div>
            <div className="w-3/4 flex items-center space-x-4">
              <div className="flex items-center space-x-2">
                <div>
                  <select name="selectedTime" className="border-2">
                    <option value="">Day</option>
                  </select>
                </div>
                <div>
                  <select name="selectedTime" className="border-2">
                    <option value="">Month</option>
                  </select>
                </div>
                <div>
                  <div>
                    <input type="text" className="border-2 w-20" />
                  </div>
                </div>
              </div>
              <div>to</div>
              <div className="flex items-center space-x-2">
                <div>
                  <select name="selectedTime" className="border-2">
                    <option value="">Day</option>
                  </select>
                </div>
                <div>
                  <select name="selectedTime" className="border-2">
                    <option value="">Month</option>
                  </select>
                </div>
                <div>
                  <div>
                    <input type="text" className="border-2 w-20" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="submit"
              className="bg-blue-800 text-white h-10 w-[100px] font-semibold rounded-sm"
              onClick={() => setShowSearchResults(true)}>
              Search
            </button>
          </div>
        </div>
        {showSearchResults && (
          <div className="search-results mb-4 p-4 bg-white">
            <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
              Search Results
            </div>
            <div className="data-table">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="a dense table">
                  <TableHead style={{ backgroundColor: "#1e40af" }}>
                    <TableRow
                      sx={{
                        "& th": {
                          color: "white",
                        },
                      }}>
                      <TableCell align="center">Course Name</TableCell>
                      <TableCell align="center">Course Type</TableCell>
                      <TableCell align="center">Course Start Date</TableCell>
                      <TableCell align="center">Course End Date</TableCell>
                      <TableCell align="center">Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell
                          align="left"
                          style={{ color: "red", cursor: "pointer" }}
                          onClick={() => navigate("/participationRecords")}>
                          {row.name}
                        </TableCell>
                        <TableCell align="left">{row.courseCode}</TableCell>
                        <TableCell align="left">{row.courseStartDate}</TableCell>
                        <TableCell align="left">{row.courseEndDate}</TableCell>
                        <TableCell align="left">{row.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EvaluationReport;
