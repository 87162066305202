import { useNavigate } from "react-router-dom";

const TrainingProviderLandingPage = () => {
  const navigate = useNavigate();

  const rows = [
    {
      applicationType: "Approved",
      records: 3,
    },
    {
      applicationType: "Submitted",
      records: 12,
    },
  ];

  const handleNavigate = () => {
    navigate("/tpCourseDetails");
  };

  return (
    <div className="task-tab">
      <div className="tab-main-heading font-bold text-lg mb-6">Courses</div>
      <div className="tab-details bg-white p-4 rounded-sm">
        <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
          Lists of Courses
        </div>
        <div className="cards-wrapper">
          <div className="flex flex-wrap [&>*:nth-child(odd)]:mr-4">
            {rows.map((row) => {
              return (
                <div
                  className="flex mb-4 items-center justify-between basis-[48%] p-4 bg-white rounded-2xl shadow-lg cursor-pointer transition duration-300 ease-linear hover:scale-105"
                  onClick={handleNavigate}
                  key={row.applicationType}>
                  <div className="text-black font-semibold">
                    {row.applicationType}
                  </div>
                  {row.records !== null && (
                    <div className="bg-blue-800 h-10 w-10 text-white rounded-full flex items-center justify-center shadow-lg font-bold mr-4">
                      {row.records}
                    </div>
                  )}
                </div>
              );
            })}
            <div
              className="flex mb-4 items-center justify-between basis-[48%] p-4 bg-white rounded-2xl border border-dashed border-black cursor-pointer transition duration-300 ease-linear hover:scale-105"
              onClick={() => navigate("/newCourse")}>
              <div className="text-black font-semibold">Add New Course</div>
              <div className="bg-blue-800 h-10 w-10 text-white rounded-full flex items-center justify-center shadow-lg font-bold mr-4">
                <i className="fa-solid fa-plus"></i>
              </div>
            </div>
            <div
              className="flex mb-4 items-center justify-between basis-[48%] p-4 bg-white rounded-2xl border border-dashed border-black cursor-pointer transition duration-300 ease-linear hover:scale-105"
              onClick={handleNavigate}>
              <div className="text-black font-semibold">Modify Course</div>
              <div className="bg-blue-800 h-10 w-10 text-white rounded-full flex items-center justify-center shadow-lg font-bold mr-4">
                <i className="fa-solid fa-pencil"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingProviderLandingPage;
