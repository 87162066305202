import {
  Box,
  Step,
  StepButton,
  Stepper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SecretariatLandingPage = () => {
  const [value, setValue] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState("task");
  const navigate = useNavigate();
  const steps = [
    "Main",
    "Course Details",
    "Speakers",
    "Outline",
    "Recommendation",
    "Decision",
  ];
  const rows = [
    {
      applicationType: "Provider - change",
      records: 3,
    },
    {
      applicationType: "Course - New",
      records: 12,
    },
    {
      applicationType: "Course - change",
      records: 1,
    },
    {
      applicationType: "Participation Record",
      records: 1,
    },
  ];

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const handleNavigate = () => {
    navigate("/courseLists");
  };

  return (
    <div className="h-96">
      <div className="bg-white h-12 rounded-3xl flex items-center mb-6">
        <div
          className={`flex-1 h-full flex justify-center items-center ${
            activeTab === "task" ? "bg-blue-800 text-white" : "text-black"
          }  rounded-3xl cursor-pointer`}
          onClick={() => setActiveTab("task")}
          tabIndex={0}>
          Task
        </div>
        <div
          className={`flex-1 h-full flex justify-center items-center cursor-pointer rounded-3xl ${
            activeTab === "reports" ? "bg-blue-800 text-white" : "text-black"
          }`}
          onClick={() => setActiveTab("reports")}
          tabIndex={0}>
          Reports
        </div>
        <div
          className={`flex-1 h-full flex justify-center items-center cursor-pointer rounded-3xl ${
            activeTab === "maintainance" ? "bg-blue-800 text-white" : "text-black"
          }`}
          onClick={() => setActiveTab("maintainance")}
          tabIndex={0}>
          Maintainance
        </div>
      </div>
      <div className="tab-contents">
        {/* <div className="p-6 bg-white rounded-sm">
          <Box sx={{ width: "100%" }}>
            <Stepper nonLinear activeStep={activeStep} className="mb-10">
              {steps.map((label, index) => (
                <Step
                  key={label}
                  sx={{
                    ".MuiStepIcon-root.Mui-active": {
                      color: "#1e40af",
                    },
                  }}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
          </Box>
        </div> */}
        {activeTab === "task" && (
          <div className="task-tab">
            <div className="tab-main-heading font-bold text-lg mb-6">
              Pending Tasks
            </div>
            <div className="tab-details bg-white p-4 rounded-sm">
              <div className="title-heading pb-4 mb-4 border-b border-b-blue-800 font-bold">
                Lists of Pending Applications
              </div>
              <div className="cards-wrapper">
                <div className="flex flex-wrap [&>*:nth-child(odd)]:mr-4">
                  {rows.map((row) => {
                    return (
                      <div
                        className="flex mb-4 items-center justify-between basis-[48%] p-4 bg-white rounded-2xl shadow-lg cursor-pointer transition duration-300 ease-linear hover:scale-105"
                        onClick={handleNavigate}
                        key={row.applicationType}>
                        <div className="text-black font-semibold">
                          {row.applicationType}
                        </div>
                        <div className="bg-blue-800 h-10 w-10 text-white rounded-full flex items-center justify-center shadow-lg font-bold mr-4">
                          {row.records}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* <div className="data-table">
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead style={{ backgroundColor: "#1e40af" }}>
                      <TableRow
                        sx={{
                          "& th": {
                            color: "white",
                          },
                        }}>
                        <TableCell align="center">Application Type</TableCell>
                        <TableCell align="center">Number of Records</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow
                          key={row.applicationType}
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell
                            align="left"
                            style={{ color: "blue", cursor: "pointer" }}
                            onClick={handleNavigate}>
                            {row.applicationType}
                          </TableCell>
                          <TableCell align="left">{row.records}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div> */}
            </div>
          </div>
        )}
        {activeTab === "reports" && <div className="reports-tab">Reports Tab</div>}
        {activeTab === "maintainance" && (
          <div className="reports-tab">Maintainance Tab</div>
        )}
        {/* {activeTab === "maintainance" && (
          <div className="reports-tab">
            <div className="tab-main-heading font-bold text-lg mb-6">
              Maintainance
            </div>
            <div className="p-4 bg-white">
              <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
                Functionalities available
              </div>
              <div className="functionalities-available">
                <ul className="list-disc ml-10">
                  <li>
                    <div className="font-semibold">Course</div>
                    <ul>
                      <li>Information</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default SecretariatLandingPage;
