import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

const AlertDialog = (props: any) => {
  const { open, handleClose, handleSubmit } = props;

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className="p-2">
          <div className="flex items-center justify-between p-2">
            <div className="font-bold">
              <span>Confirm</span>
            </div>
            <div className="cursor-pointer" onClick={handleClose}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
          <div className="p-2">
            <div className="mb-6 flex items-center space-x-4">
              <span className="font-semibold">
                Are you sure want to process this application?
              </span>
            </div>
            <div className="flex items-center justify-end space-x-2">
              <div>
                <button
                  type="submit"
                  className="bg-blue-800 text-white px-4 py-1 rounded-md font-semibold"
                  onClick={handleSubmit}>
                  Ok
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="bg-blue-800 text-white px-4 py-1 rounded-md font-semibold"
                  onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertDialog;
