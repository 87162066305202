import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
} from "@mui/material";

const ParticipationRecords = () => {
  const rows = [
    {
      applicationType: "Provider - change",
      records: 3,
    },
    {
      applicationType: "Course - New",
      records: 12,
    },
    {
      applicationType: "Course - change",
      records: 1,
    },
    {
      applicationType: "Participation Record",
      records: 1,
    },
  ];
  return (
    <div>
      <div className="heading flex items-center justify-start mb-6">
        <div className="heading-icon text-sm w-9 h-9 rounded-md bg-blue-800 text-white flex items-center justify-center mr-2">
          <i className="fa-solid fa-house"></i>
        </div>
        <div className="text-md font-bold">Partcipation Records</div>
      </div>
      <div className="content">
        <div className="bg-white rounded-sm mb-4 p-4">
          <div className="course-details">
            <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
              Course Details
            </div>
            <div className="flex items-center my-2">
              <div className="font-semibold w-1/4">Training Provider</div>
              <div className="w-3/4">Securities Industry Development Center</div>
            </div>
            <div className="flex items-center my-2">
              <div className="font-semibold w-1/4">Course Name</div>
              <div className="w-3/4">
                Securities Regulation: Professional Conduct and Ethics
              </div>
            </div>
            <div className="flex items-center my-2">
              <div className="font-semibold w-1/4">Course Code</div>
              <div className="w-3/4">SIDC-0001-001</div>
            </div>
            <div className="flex items-center my-2">
              <div className="font-semibold w-1/4">Course Start Date</div>
              <div className="w-3/4">20 OCT 2023</div>
            </div>
            <div className="flex items-center my-2">
              <div className="font-semibold w-1/4">Course End Date</div>
              <div className="w-3/4">20 OCT 2023</div>
            </div>
            <div className="flex items-center my-2">
              <div className="font-semibold w-1/4">
                Licensed Participants or Employee of Registered Person(ERP)
              </div>
              <div className="w-3/4 flex items-center space-x-2">
                <div>
                  <input type="checkbox" />
                </div>
                <div>None</div>
              </div>
            </div>
          </div>
        </div>
        <div className="records-data-table bg-white p-4 mb-4">
          <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
            Record(s) in this Application
          </div>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead style={{ backgroundColor: "#1e40af" }}>
                <TableRow
                  sx={{
                    "& th": {
                      color: "white",
                    },
                  }}>
                  <TableCell align="center">Receipt No</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">NRIC/Passport</TableCell>
                  <TableCell align="center">Complaiont?</TableCell>
                  <TableCell align="center">Action</TableCell>
                  <TableCell align="center">Participation Date</TableCell>
                  <TableCell align="center">Type</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {rows.map((row) => (
                  <TableRow
                    key={row.applicationType}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell
                      align="left"
                      style={{ color: "blue", cursor: "pointer" }}>
                      {row.applicationType}
                    </TableCell>
                    <TableCell align="left">{row.records}</TableCell>
                    <TableCell align="left">{row.records}</TableCell>
                    <TableCell align="left">{row.records}</TableCell>
                    <TableCell align="left">{row.records}</TableCell>
                    <TableCell align="left">{row.records}</TableCell>
                    <TableCell align="left">{row.records}</TableCell>
                  </TableRow>
                ))} */}
                <div>No Data Found</div>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="participation-records-form bg-white p-4 mb-4">
          <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
            Participation Recors Form
          </div>
          <div className="flex items-center my-2 space-x-10">
            <div className="flex items-center space-x-2">
              <div className="">
                <input type="radio" name="record" />
              </div>
              <div className="font-semibold">NRIC</div>
            </div>

            <div className="flex items-center space-x-2 w-3/4">
              <div>
                <input type="text" className="border-2 w-20" />
              </div>
              <div>-</div>
              <div>
                <input type="text" className="border-2 w-20" />
              </div>
              <div>-</div>
              <div>
                <input type="text" className="border-2 w-20" />
              </div>
            </div>
          </div>
          <div className="flex items-center my-2 space-x-2">
            <div className="">
              <input type="radio" name="record" />
            </div>
            <div className="font-semibold">Passport</div>
          </div>
          <div>
            <button
              type="submit"
              className="bg-blue-800 text-white p-2 font-semibold rounded-sm">
              Search In Accepted Records
            </button>
          </div>
        </div>
        <div className="import-excel bg-white p-4 mb-4">
        <div className="title-heading font-semibold pb-3 border-b-blue-800 border-b mb-4">
            Import From Excel
          </div>
          <div className="flex items-center my-2">
            <div className="font-semibold w-1/4">
              Please select the excel file to upload
            </div>
            <div className="flex items-center space-x-2">
              <div className="w-3/4">
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  inputProps={{
                    sx: {
                      padding: "10px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      padding: "0px",
                      top: "-6px",
                    },
                  }}
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="bg-blue-800 text-white h-10 w-[100px] font-semibold rounded-sm">
                  Browse...
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className="bg-blue-800 text-white h-10 w-[100px] font-semibold rounded-sm">
                  Upload
                </button>
              </div>
            </div>
          </div>
          <div>
            You must use the following template file for upload. Please download this
            file and fill it with your data before uploading.
          </div>
          <div className="text-blue-800 font-semibold cursor-pointer">
            Download Template
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipationRecords;
