import { useState } from "react";
import Logo from "../../public/logo.png";
import { Button, Menu, MenuItem } from "@mui/material";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorTrainingProvider, setAnchorTrainingProvider] =
    useState<null | HTMLElement>(null);
  const [anchorCourses, setAnchorCourses] = useState<null | HTMLElement>(null);
  const [anchorReports, setAnchorReports] = useState<null | HTMLElement>(null);
  const [anchorSCourse, setAnchorSCourse] = useState<null | HTMLElement>(null);
  const [anchorSTP, setAnchorSTP] = useState<null | HTMLElement>(null);
  const [anchorSReports, setAnchorSReports] = useState<null | HTMLElement>(null);
  const [anchorSSearch, setAnchorSSearch] = useState<null | HTMLElement>(null);
  const openLogoutMenu = Boolean(anchorEl);
  const openTrainingProviderMenu = Boolean(anchorTrainingProvider);
  const openCoursesMenu = Boolean(anchorCourses);
  const openReportsMenu = Boolean(anchorReports);
  const openSCourse = Boolean(anchorSCourse);
  const openSTP = Boolean(anchorSTP);
  const openSReports = Boolean(anchorSReports);
  const openSSearch = Boolean(anchorSSearch);
  const trainingProviderMenuList = [
    "Account Management",
    "Profile",
    "Cessation",
    "Application Draft(s)",
    "Search Application",
  ];
  const courseMenuList = [
    "Catalog",
    "Course Application",
    "Application Draft(s)",
    "Search Application",
  ];
  const secreteriatCourse = [
    "New Course",
    "Change Course Particulars",
    "Repeat Course",
    "Resume Course",
    "ETV Course",
    "Cancel Course",
    "Draft(s)",
  ];
  const secreteriatTP = ["Profile Change", "Cessation", "Draft(s)"];
  const secreteriatReportsSubmission = [
    "Participation Record",
    "Evaluation",
    "Report Status",
  ];
  const secreteriatSearch = ["Application"];
  const reportMenuList = ["Evaluation", "Participation", "Report Status"];
  const navigate = useNavigate();

  const handleLogoutClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>, type: string) => {
    if (type === "trainingProvider") {
      setAnchorTrainingProvider(event.currentTarget);
    } else if (type === "courses") {
      setAnchorCourses(event.currentTarget);
    } else if (type === "reports") {
      setAnchorReports(event.currentTarget);
    } else if (type === "SCourse") {
      setAnchorSCourse(event.currentTarget);
    } else if (type === "STrainingProvider") {
      setAnchorSTP(event.currentTarget);
    } else if (type === "SReports") {
      setAnchorSReports(event.currentTarget);
    } else if (type === "SSearch") {
      setAnchorSSearch(event.currentTarget);
    }
  };
  const handleLogoutClose = () => {
    setAnchorEl(null);
  };
  const handleClose = (type: string) => {
    if (type === "trainingProvider") {
      setAnchorTrainingProvider(null);
    } else if (type === "courses") {
      setAnchorCourses(null);
    } else if (type === "reports") {
      setAnchorReports(null);
    } else if (type === "SCourse") {
      setAnchorSCourse(null);
    } else if (type === "STrainingProvider") {
      setAnchorSTP(null);
    } else if (type === "SReports") {
      setAnchorSReports(null);
    } else if (type === "SSearch") {
      setAnchorSSearch(null);
    }
  };

  const handleMenuClick = (menu: string) => {
    if (menu === "Participation") {
      navigate("/reports");
    }
    setAnchorReports(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const token = localStorage?.getItem("token");

  return (
    <>
      <div className="layout-header h-14 bg-white">
        <div className="flex justify-between items-center h-full px-28">
          <div className="logo flex items-center">
            <div className="logo-image mr-5">
              <img src={Logo} width={60} />
            </div>
            <div className="title text-lg font-semibold italic">
              CPE Tracking System
            </div>
          </div>
          <div className="account-details flex items-center">
            <div className="user-details flex items-center mr-4">
              <div className="user-image w-8 h-8 rounded-full bg-blue-800 text-white flex justify-center items-center mr-4 text-sm">
                <i className="fa-solid fa-user"></i>
              </div>
              <div className="user-info text-sm mr-6">
                <p>Username: Yong Tak Chong</p>
                <p>
                  Role:{" "}
                  {token === "head"
                    ? "Head"
                    : token === "trainingprovider"
                    ? "Training Provider"
                    : token === "secretariat"
                    ? "Secretariat"
                    : token === "workingCommittee"
                    ? "Working Committee"
                    : token === "manager"
                    ? "Manager"
                    : ""}
                </p>
              </div>
            </div>
            <div className="logout ml-4 text-blue-800">
              <i
                className="fa-solid fa-power-off cursor-pointer"
                onClick={handleLogoutClick}></i>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={openLogoutMenu}
                onClose={handleLogoutClose}>
                {["Logout"].map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={handleLogout}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="Layout-Menulist h-12 bg-blue-800 text-white px-28">
        {token === "trainingprovider" && (
          <div className="flex justify-start items-center h-full">
            <div className="h-full border-r border-white">
              <Button
                id="training-provider-button"
                aria-controls={
                  openTrainingProviderMenu ? "training-provider-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openTrainingProviderMenu ? "true" : undefined}
                onClick={(event) => handleClick(event, "trainingProvider")}
                startIcon={<i className="fa-solid fa-user-gear"></i>}
                classes={{
                  root: styles.button,
                  startIcon: styles.startIcon,
                }}
                className="h-full">
                Training Provider
              </Button>
              <Menu
                id="training-provider-menu"
                MenuListProps={{
                  "aria-labelledby": "training-provide-button",
                }}
                anchorEl={anchorTrainingProvider}
                open={openTrainingProviderMenu}
                onClose={() => handleClose("trainingProvider")}>
                {trainingProviderMenuList.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleMenuClick(option);
                    }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="h-full border-r border-white">
              <Button
                id="course-button"
                aria-controls={openCoursesMenu ? "course-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openCoursesMenu ? "true" : undefined}
                onClick={(event) => handleClick(event, "courses")}
                startIcon={<i className="fa-solid fa-book"></i>}
                classes={{
                  root: styles.button,
                  startIcon: styles.startIcon,
                }}
                className="h-full">
                Courses
              </Button>
              <Menu
                id="course-menu"
                MenuListProps={{
                  "aria-labelledby": "course-button",
                }}
                anchorEl={anchorCourses}
                open={openCoursesMenu}
                onClose={() => handleClose("courses")}>
                {courseMenuList.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleMenuClick(option);
                    }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="h-full border-r border-white">
              <Button
                id="reports-button"
                aria-controls={openReportsMenu ? "reports-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openReportsMenu ? "true" : undefined}
                onClick={(event) => handleClick(event, "reports")}
                startIcon={<i className="fa-regular fa-file-lines"></i>}
                classes={{
                  root: styles.button,
                  startIcon: styles.startIcon,
                }}
                className="h-full">
                Reports
              </Button>
              <Menu
                id="reports-menu"
                MenuListProps={{
                  "aria-labelledby": "reports-button",
                }}
                anchorEl={anchorReports}
                open={openReportsMenu}
                onClose={() => handleClose("reports")}>
                {reportMenuList.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleMenuClick(option);
                    }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        )}
        {(token === "secretariat" ||
          token === "manager" ||
          token === "head" ||
          token === "workingCommittee") && (
          <div className="flex justify-start items-center h-full">
            <div className="h-full border-r border-white">
              <Button
                id="training-provider-button"
                aria-controls={openSCourse ? "training-provider-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSCourse ? "true" : undefined}
                onClick={(event) => handleClick(event, "SCourse")}
                startIcon={<i className="fa-solid fa-book"></i>}
                classes={{
                  root: styles.button,
                  startIcon: styles.startIcon,
                }}
                className="h-full">
                Course
              </Button>
              <Menu
                id="training-provider-menu"
                MenuListProps={{
                  "aria-labelledby": "training-provide-button",
                }}
                anchorEl={anchorSCourse}
                open={openSCourse}
                onClose={() => handleClose("SCourse")}>
                {secreteriatCourse.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleMenuClick(option);
                    }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="h-full border-r border-white">
              <Button
                id="training-provider-button"
                aria-controls={openSTP ? "training-provider-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSTP ? "true" : undefined}
                onClick={(event) => handleClick(event, "STrainingProvider")}
                startIcon={<i className="fa-solid fa-user-gear"></i>}
                classes={{
                  root: styles.button,
                  startIcon: styles.startIcon,
                }}
                className="h-full">
                Training Provider
              </Button>
              <Menu
                id="training-provider-menu"
                MenuListProps={{
                  "aria-labelledby": "training-provide-button",
                }}
                anchorEl={anchorSTP}
                open={openSTP}
                onClose={() => handleClose("STrainingProvider")}>
                {secreteriatTP.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleMenuClick(option);
                    }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="h-full border-r border-white">
              <Button
                id="reports-button"
                aria-controls={openSReports ? "reports-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSReports ? "true" : undefined}
                onClick={(event) => handleClick(event, "SReports")}
                startIcon={<i className="fa-regular fa-file-lines"></i>}
                classes={{
                  root: styles.button,
                  startIcon: styles.startIcon,
                }}
                className="h-full">
                Reports Submission
              </Button>
              <Menu
                id="reports-menu"
                MenuListProps={{
                  "aria-labelledby": "reports-button",
                }}
                anchorEl={anchorSReports}
                open={openSReports}
                onClose={() => handleClose("SReports")}>
                {secreteriatReportsSubmission.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleMenuClick(option);
                    }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
            <div className="h-full border-r border-white">
              <Button
                id="reports-button"
                aria-controls={openSSearch ? "reports-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openSSearch ? "true" : undefined}
                onClick={(event) => handleClick(event, "SSearch")}
                startIcon={<i className="fa-solid fa-magnifying-glass"></i>}
                classes={{
                  root: styles.button,
                  startIcon: styles.startIcon,
                }}
                className="h-full">
                Search
              </Button>
              <Menu
                id="reports-menu"
                MenuListProps={{
                  "aria-labelledby": "reports-button",
                }}
                anchorEl={anchorSSearch}
                open={openSSearch}
                onClose={() => handleClose("SSearch")}>
                {secreteriatSearch.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === "Pyxis"}
                    onClick={() => {
                      handleMenuClick(option);
                    }}>
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;

{
  /* <div className="menu-item flex items-center px-3 py-2 border-r h-full cursor-pointer">
          <div className="menu-item-icon mr-3">
            <i className="fa-solid fa-user-gear"></i>
          </div>
          <div className="menu-item-icon">Training Providers</div>
        </div> */
}
