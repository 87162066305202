// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Z8li2I9OXw392coiACbS {\r\n    color: white;\r\n    padding: 10px 20px;\r\n    font-size: 12px;\r\n}\r\n.gc6_6rY9WyCSUMnvxi1u > *:first-child{ \r\n    font-size: 16px;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Header.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,eAAe;AACnB","sourcesContent":[".button {\r\n    color: white;\r\n    padding: 10px 20px;\r\n    font-size: 12px;\r\n}\r\n.startIcon > *:first-child{ \r\n    font-size: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Z8li2I9OXw392coiACbS",
	"startIcon": "gc6_6rY9WyCSUMnvxi1u"
};
export default ___CSS_LOADER_EXPORT___;
